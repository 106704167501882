.a_ {
    text-decoration: none;
}

.card-img-top_ {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

.center-item {
    position: fixed;
    left: 50%;
    top: 50%;
}